<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="orders" v-model:selection="selectedRow" :metaKeySelection="false"
                    v-model:filters="filters1" filterDisplay="menu" dataKey="id"
                    :globalFilterFields="['orderid','name','mpn']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="allRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入订单号或客户名称或MPN模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.orderid" @click="openOrder(slotProps.data)"
                                class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :pageJsonConfig="pageJsonConfig" :dropOtherConfig="dropOtherConfig"
                :importAddColsMethod="importAddCols" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="上传订单(非圈货)数据" v-model:visible="importDisplay" :style="{width: '30vw'}" :maximizable="true"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="!startImport" class="field col-12 md:col-12">
                <FileUpload mode="basic" id="importtemplateFileId" style="width:100%" name="importtemplateFile"
                    :url="uploadurl" accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload"
                    @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                    invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <label>{{importfailureinfo}}</label>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport" />
            </div>
        </template>
    </Dialog>
    <Dialog header="上传圈货订单数据" v-model:visible="importDisplay2" :style="{width: '30vw'}" :maximizable="true"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="!startImport2" class="field col-12 md:col-12">
                <FileUpload mode="basic" id="importtemplateFileId2" style="width:100%" name="importtemplateFile2"
                    :url="uploadurl" accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload2"
                    @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                    invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
            </div>
            <div v-if="startImport2" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue2" />
            </div>
            <div v-if="startImport2" class="field col-12 md:col-12">
                <label>{{importProgress2}}</label>
            </div>
            <div v-if="startImport2" class="field col-12 md:col-12">
                <label>{{importfailureinfo2}}</label>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport2" />
            </div>
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import orderPageJson from "@/data/orderConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '719';
            const allRecords = -1;
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                allRecords,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                uploadurl: '',
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                excelDatas: [],
                startImport: false,
                importProgress: '',
                importfailureinfo: '',
                importProgressValue: 0,
                startImport2: false,
                importProgress2: '',
                importfailureinfo2: '',
                importProgressValue2: 0,
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                importDisplay: false,
                importDisplay2: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: 'order',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                selectedRow: ref(),
                pageJsonConfig: {},
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                first: 0,
                pagesize: 50,
                listTitle: '未完成订单',
                selectedKey: {
                    719: true
                },
                expandedKey: null,
                orders: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '订单操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '导入订单(非圈货)',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.importOrder();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '导入圈货订单',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.importMarginagreementdeposit();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delOrder();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                // importTemplateId: '317180668514373',
                importTemplateId: '200028822564933',
                importTemplateId2: '318893724631109',
                dropOtherConfig: {
                    currency: 'exchangerate',
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getorder(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.loadMenuTree();
                    this.importProgress = '';
                    this.importfailureinfo = '';
                    this.startImport = false;
                    this.importProgressValue = 0;
                    this.excelDatas = [];
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    this.loadUserAuth();
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.allRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('718', this.currentMenuId, this.$route, this.loadData, this.setlistTitleFun, this
                    .setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '718',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var importorderflag = this.$route.query['importorder'];
                        if (importorderflag) {
                            this.importOrder();
                        }
                        var importorderflag2 = this.$route.query['importmarginorder'];
                        if (importorderflag2) {
                            this.importMarginagreementdeposit();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '未完成订单', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'finish',
                        value: '0',
                        operation: 'equals'
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                console.log('loadData....');
                console.log(this.filters1);
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'orderid,name,mpn',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{orderlist(where:" + JSON.stringify(listwhere) +
                    "){id orderid name buyercode mpn itemcode productname salesdate salesquantity unitprice currencyename total finaltotal ordertype isreturnname rmaid storename operator}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.orders = jsonData.data.orderlist;
                        this.allRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                        console.log(this.allRecords);
                    }
                });
            },
            importOrder() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateOrder')) {
                    MessageTip.warnmsg('请联系管理员开通订单导入权限');
                    return;
                }
                this.importProgress = '';
                this.importfailureinfo = '';
                this.startImport = false;
                this.importProgressValue = 0;
                this.importDisplay = true;
                this.excelDatas = [];
            },
            importMarginagreementdeposit() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateOrder')) {
                    MessageTip.warnmsg('请联系管理员开通订单导入权限');
                    return;
                }
                this.importProgress2 = '';
                this.importfailureinfo2 = '';
                this.startImport2 = false;
                this.importProgressValue2 = 0;
                this.importDisplay2 = true;
                this.excelDatas = [];
            },
            delOrder() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要删除订单');
                    return;
                }
                var orderIds = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    orderIds.push({
                        "id": this.selectedRow[k1]["id"]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "delete{orderlist(o:" + JSON.stringify(orderIds) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        MessageTip.successmsg('删除成功');
                    } else {
                        MessageTip.warnmsg('删除失败');
                    }
                });
            },
            openOrder(v_data) {
                this.recordsubject = '订单' + v_data.orderid;
                this.currentRow = {
                    bindpage: 'order',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.displayMaximizable = false;
                this.butLoading = false;
                this.loadData(1);
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            onImportUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/ExcelTemplateImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            templateid: this.importTemplateId,
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            this.excelDatas = JSON.parse(unescape(Base64.decode(res.data)));
                            this.startImport = true;
                            this.uploadExcelData();
                        }
                    });
                } else {
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeImportUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '1');
                event.formData.append('attachttype', '1');
            },
            closeImport() {
                this.loadData(1);
                this.importProgress = '';
                this.importfailureinfo = '';
                this.startImport = false;
                this.importProgressValue = 0;
                this.excelDatas = [];
                this.importDisplay = false;
            },
            onImportUpload2(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/ExcelTemplateImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            templateid: this.importTemplateId2,
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            this.excelDatas = JSON.parse(unescape(Base64.decode(res.data)));
                            this.startImport2 = true;
                            this.uploadExcelData2();
                        }
                    });
                } else {
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            closeImport2() {
                this.loadData(1);
                this.importProgress2 = '';
                this.importfailureinfo2 = '';
                this.startImport2 = false;
                this.importProgressValue2 = 0;
                this.excelDatas = [];
                this.importDisplay2 = false;
            },
            uploadExcelData2() {
                if (this.excelDatas.length > 0) {
                    this.importProgress2 = '共' + this.excelDatas[0]['arrayData'].length + '条数据,已导入0条,还剩余' + this
                        .excelDatas[0]['arrayData'].length + '条';
                    setTimeout(() => {
                        this.continueUploadExcelData2(this.excelDatas[0]['arrayData'], 0, 0, 0, 0);
                    }, 10);
                }
            },
            continueUploadExcelData2(alldatas, importIndex, failureIndex, currentIndex, ignoreIndex) {
                if (alldatas.length > currentIndex) {
                    this.importProgress2 = '共' + alldatas.length + '条数据,正导入第' + (currentIndex + 1) +
                        '条数据,已成功导入' + importIndex + '条,失败' + failureIndex + '条,忽略' + ignoreIndex + '条,还剩余' + (alldatas
                            .length - importIndex -
                            failureIndex - ignoreIndex) + '条';
                    var orderObj = alldatas[currentIndex];
                    orderObj['id'] = '-1';
                    orderObj['operatorid'] = sessionStorage.getItem("userid");
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "import{order(o:" + JSON.stringify(orderObj) +
                        "){id errmsg}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            importIndex = importIndex + 1;
                        } else {
                            MessageTip.warnmsg('导入第' + (currentIndex + 1) + '条数据失败');
                            failureIndex = failureIndex + 1;
                            if (this.importfailureinfo2 == '') {
                                this.importfailureinfo2 = '导入第' + (currentIndex + 1) +
                                    '条数据失败(' + res.err + ')';
                            } else {
                                this.importfailureinfo2 = this.importfailureinfo2 + ';导入第' + (currentIndex +
                                        1) +
                                    '条数据失败(' + res.err + ')';
                            }
                        }
                        currentIndex = currentIndex + 1;
                        this.importProgressValue2 = (((importIndex + failureIndex + ignoreIndex) / alldatas
                                .length) * 100)
                            .toFixed(0);
                        console.log('importProgressValue2:' + this.importProgressValue2);
                        console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                        if (this.startImport2) {
                            setTimeout(() => {
                                this.continueUploadExcelData2(alldatas, importIndex, failureIndex,
                                    currentIndex, ignoreIndex);
                            }, 10);
                        }
                    });
                } else {
                    this.importProgress2 = '共' + alldatas.length + '条数据,已成功导入' + importIndex +
                        '条,失败' + failureIndex + '条,忽略' + ignoreIndex + '条,还剩余' + (alldatas.length - importIndex -
                            failureIndex - ignoreIndex) + '条';
                }
            },
            uploadExcelData() {
                if (this.excelDatas.length > 0) {
                    this.importProgress = '共' + this.excelDatas[0]['arrayData'].length + '条数据,已导入0条,还剩余' + this
                        .excelDatas[0]['arrayData'].length + '条';
                    setTimeout(() => {
                        this.continueUploadExcelData(this.excelDatas[0]['arrayData'], 0, 0, 0, 0);
                    }, 10);
                }
            },
            continueUploadExcelData(alldatas, importIndex, failureIndex, currentIndex, ignoreIndex) {
                if (alldatas.length > currentIndex) {
                    this.importProgress = '共' + alldatas.length + '条数据,正导入第' + (currentIndex + 1) +
                        '条数据,已成功导入' + importIndex + '条,失败' + failureIndex + '条,忽略' + ignoreIndex + '条,还剩余' + (alldatas
                            .length - importIndex -
                            failureIndex - ignoreIndex) + '条';
                    var orderObj = alldatas[currentIndex];
                    if (orderObj['rmaid'] && orderObj['rmaid'] != '') {
                        this.createClaimSettlement(orderObj, alldatas, importIndex, failureIndex, currentIndex,
                            ignoreIndex);
                    } else {
                        orderObj['id'] = '-1';
                        orderObj['operatorid'] = sessionStorage.getItem("userid");
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "import{order(o:" + JSON.stringify(orderObj) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                importIndex = importIndex + 1;
                            } else {
                                MessageTip.warnmsg('导入第' + (currentIndex + 1) + '条数据失败');
                                failureIndex = failureIndex + 1;
                                if (this.importfailureinfo == '') {
                                    this.importfailureinfo = '导入第' + (currentIndex + 1) +
                                        '条数据失败(' + res.err + ')';
                                } else {
                                    this.importfailureinfo = this.importfailureinfo + ';导入第' + (currentIndex +
                                            1) +
                                        '条数据失败(' + res.err + ')';
                                }
                            }
                            currentIndex = currentIndex + 1;
                            this.importProgressValue = (((importIndex + failureIndex + ignoreIndex) / alldatas
                                    .length) * 100)
                                .toFixed(0);
                            console.log('importProgressValue:' + this.importProgressValue);
                            console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                            if (this.startImport) {
                                setTimeout(() => {
                                    this.continueUploadExcelData(alldatas, importIndex, failureIndex,
                                        currentIndex, ignoreIndex);
                                }, 10);
                            }
                        });
                    }
                } else {
                    this.importProgress = '共' + alldatas.length + '条数据,已成功导入' + importIndex +
                        '条,失败' + failureIndex + '条,忽略' + ignoreIndex + '条,还剩余' + (alldatas.length - importIndex -
                            failureIndex - ignoreIndex) + '条';
                }
            },
            createClaimSettlement(order, alldatas, importIndex, failureIndex, currentIndex, ignoreIndex) {
                console.log(order);
                currentIndex = currentIndex + 1;
                ignoreIndex = ignoreIndex + 1;
                this.importProgressValue = (((importIndex + failureIndex + ignoreIndex) / alldatas.length) * 100)
                    .toFixed(0);
                console.log('importProgressValue:' + this.importProgressValue);
                console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                if (this.startImport) {
                    setTimeout(() => {
                        this.continueUploadExcelData(alldatas, importIndex, failureIndex,
                            currentIndex, ignoreIndex);
                    }, 10);
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
        }
    }
</script>